@import url("https://rsms.me/inter/inter.css");
html {
    /* this enables some alternative styles and contextual characters to the font */
    font-feature-settings: 'ss01', 'ss02', 'case', 'salt';
}
body {
    margin: 0;
    font-family: "Inter", serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));
}